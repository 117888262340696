function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const object = {
  dictKey: 'dict://',
  reCaptcha: '',
  localstorageKey: 'factoryv1',
  endpoints: Object.freeze({
    auth: 'auth',
    fct_users: 'fct_users',
    fct_config: 'fct_config',
    logs: 'fct_log',
    fct_log: 'fct_log',
    fct_permisos: 'fct_permisos',
    fct_rolesypermisos: 'fct_rolesypermisos',
    fct_rutas: 'fct_rutas',
    fct_rutaxdevices: 'fct_rutaxdevices',
    fct_rutaxdevicexgeofences: 'fct_rutaxdispositivoxgeofences',
    fct_userxdevice: 'fct_userxdevice',
    fct_userxruta: 'fct_userxruta',
    devices: 'devices',
    events: 'events',
    geofences: 'geofences',
    fct_roles: 'fct_roles',
  }),
  dictionaryIndexes: Object.freeze({
    fct_users: 15,
    fct_config: 15,
    fct_log: 9999,
    fct_permisos: 9999,
    fct_rolesypermisos: 9999,
    fct_rutas: 5,
    fct_rutaxdevices: 15,
    fct_rutaxdevicexgeofences: 15,
    fct_rutaxgeofences: 15,
    fct_userxdevice: 9999,
    fct_userxruta: 9999,
    devices: 15,
    events: 9999,
    geofences: 15,
    fct_roles: 15,
  }),
  VAPIDPublicKey: urlBase64ToUint8Array(
    'BP2AliyxfTyuw6q_be_AdScBhjZN8USyE3V8QPHV3OKnjHARAKuXAboYfY5_yDL-CyBUtZik73sHuTGo3ELOa-I'
  ),
};

switch (process.env.NODE_ENV) {
  case 'production':
    object.apiURL = 'https://linea23.gpsblue.world/api/v1/';
    object.uploadsURL = '/uploads/';
    break;
  default:
    object.apiURL = 'http://localhost/andyfactory/v1/';
    object.uploadsURL = 'http://localhost/andyfactory/uploads/';
    break;
}

export default object;
